import PFElement from "@patternfly/pfelement"

class SingleCardBorderedTop extends PFElement {
  static get tag() {
    return "single-card-bordered-top"
  }

  static get events() {
    return { ClickEvent: `${this.tag}:click` }
  }

  set data(data) {
    this.magnifyGlassIcon.setAttribute("style", "display: none")
    this.relatedSearchIcon.setAttribute("style", "display: none")
    this.linkOne.textContent = data?.[0]?.text || ""
    this.linkOne.href = data?.[0]?.link || ""
    this.linkTwo.textContent = data?.[1]?.text || ""
    this.linkTwo.href = data?.[1]?.link || ""
    this.linkThree.textContent = data?.[2]?.text || ""
    this.linkThree.href = data?.[2]?.link || ""

    if(data?.title == "Top pages"){
      this.magnifyGlassIcon.setAttribute("style", "display: block");
      this.cardTitle.textContent = "Explore top pages"
    }else if(data?.title == "People also searched for" || data?.title == "Popular with similar users"){
      this.relatedSearchIcon.setAttribute("style", "display: block")
      this.cardTitle.textContent = data?.title
    }
    if(data?.title == "Popular with similar users"){
      this.bodyContainer.setAttribute("style", "flex-direction: column")
    }
    if(this.linkTwo.textContent == ""){
      this.linkTwo.setAttribute("style", "display: none")
    }
    if(this.linkThree.textContent == ""){
      this.linkThree.setAttribute("style", "display: none")
    }
  }

  get html() {
    return `
    <style>
      :host{ display:block }
      .result-card{
        border-radius: 3px;
        border: 1px solid #D2D2D2;
        border-top: none;
        margin-bottom: 32px;
        margin-right: 0;
      }

      .card-body{
        padding: 0 24px 16px 24px;
      }

      .link-one, .link-two, .link-three{
        padding-bottom: 16px;
      }

      .top-border{
        position: relative;
        background-color: #EE0000;
        height: 4px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        margin-left: -1px;
        margin: 0 -1px;
      }

      .heading-container {
        border-bottom: 1px solid #E2E2E2;
        display: flex;
        align-items: center;
      }

      .title{
        font-family: 'Red Hat Text';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
        color: #000000;
        margin-top: 24px;
        margin-bottom: 16px;
      }

      svg{
        margin: 16px 12px 12px 0;
      }

      a{
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #0066CC;
        text-decoration: none;
        margin-right: 5px;
      }

      .body-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 24px;
      }
      
      @media (max-width: 768px) {
        .result-card{
          margin-bottom: 16px;
        }
        .title{
          font-size: 18px;
        }
        a{
          font-size: 16px;
        }
      }

      @media (max-width: 480px) {
        .result-card{
          margin: 0 16px 16px 16px;
        }
        .card-body{
          padding: 0 16px 16px 16px;
        }

        .link-one, .link-two, .link-three{
          padding-bottom: 0;
        }

        .body-container{
          margin-top: 0;
          flex-direction: column;

        }

        .title{
          margin-top: 20px;
          margin-bottom: 12px;
        }

        svg{
          width: 24px;
          height: 24px;
        }

        a{
          margin-top: 16px;
          margin-right: 0;
        }

      }
    </style>
      <div class="result-card">
        <div class="top-border"></div>
        <div class="card-body">
          <div class="heading-container">
            <svg data-testid="magnify-glass-icon" aria-hidden="true" class="magnify-glass-svg" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.12 0C8.11846 0 6.16186 0.593527 4.49763 1.70553C2.83341 2.81753 1.5363 4.39806 0.770344 6.24724C0.00438488 8.09643 -0.196025 10.1312 0.194457 12.0943C0.58494 14.0574 1.54878 15.8606 2.96408 17.2759C4.37939 18.6912 6.1826 19.6551 8.14569 20.0455C10.1088 20.436 12.1436 20.2356 13.9928 19.4697C15.8419 18.7037 17.4225 17.4066 18.5345 15.7424C19.6465 14.0781 20.24 12.1215 20.24 10.12C20.2374 7.43682 19.1703 4.8643 17.273 2.967C15.3757 1.06971 12.8032 0.00264744 10.12 0ZM10.12 19C8.36371 19 6.64685 18.4792 5.18654 17.5034C3.72623 16.5277 2.58806 15.1408 1.91595 13.5182C1.24385 11.8956 1.06799 10.1101 1.41063 8.3876C1.75327 6.66505 2.59901 5.08278 3.8409 3.84089C5.08279 2.599 6.66505 1.75326 8.3876 1.41063C10.1102 1.06799 11.8956 1.24384 13.5182 1.91595C15.1408 2.58806 16.5277 3.72623 17.5035 5.18654C18.4792 6.64684 19 8.3637 19 10.12C18.9947 12.4735 18.0575 14.7291 16.3933 16.3933C14.7291 18.0575 12.4735 18.9947 10.12 19Z" fill="#151515"/>
              <path d="M27.06 26.18L21.06 20.18C20.5 19.6 19.6 20.5 20.18 21.06L26.18 27.06C26.2967 27.1767 26.455 27.2423 26.62 27.2423C26.785 27.2423 26.9433 27.1767 27.06 27.06C27.1767 26.9433 27.2423 26.7851 27.2423 26.62C27.2423 26.455 27.1767 26.2967 27.06 26.18Z" fill="#151515"/>
              <path d="M10.12 3C8.23166 3 6.42066 3.75014 5.0854 5.0854C3.75014 6.42066 3 8.23166 3 10.12C3 10.2014 3.01604 10.282 3.04719 10.3573C3.07835 10.4325 3.12402 10.5008 3.18159 10.5584C3.23917 10.616 3.30751 10.6616 3.38274 10.6928C3.45796 10.724 3.53858 10.74 3.62 10.74C3.70142 10.74 3.78204 10.724 3.85726 10.6928C3.93249 10.6616 4.00083 10.616 4.05841 10.5584C4.11598 10.5008 4.16165 10.4325 4.19281 10.3573C4.22396 10.282 4.24 10.2014 4.24 10.12C4.24264 8.56134 4.86299 7.06727 5.96513 5.96513C7.06727 4.86299 8.56134 4.24264 10.12 4.24C10.2844 4.24 10.4421 4.17468 10.5584 4.05841C10.6747 3.94213 10.74 3.78443 10.74 3.62C10.74 3.45557 10.6747 3.29787 10.5584 3.18159C10.4421 3.06532 10.2844 3 10.12 3Z" fill="#151515"/>
              <path d="M18.62 18C18.4946 17.998 18.3714 18.0335 18.2663 18.102C18.1612 18.1704 18.0789 18.2687 18.0299 18.3842C17.981 18.4997 17.9677 18.6272 17.9917 18.7504C18.0157 18.8735 18.0759 18.9867 18.1646 19.0754C18.2533 19.1641 18.3665 19.2243 18.4896 19.2483C18.6127 19.2722 18.7402 19.2589 18.8557 19.21C18.9713 19.1611 19.0695 19.0788 19.138 18.9737C19.2065 18.8686 19.242 18.7454 19.24 18.62C19.24 18.4555 19.1747 18.2978 19.0584 18.1816C18.9421 18.0653 18.7844 18 18.62 18Z" fill="#151515"/>
            </svg>
            <svg data-testid="related-search-icon" aria-hidden="true" class="related-search-svg" width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.56353 29.9878H20.7454C21.1696 29.9878 21.4969 29.6484 21.4969 29.2363V18.3272C21.4969 17.9151 21.1575 17.5757 20.7454 17.5757H14.6848C13.6908 17.5757 13.6908 19.0908 14.6848 19.0908H19.9939V28.4848H3.31505V19.0908H8.62415C9.6181 19.0908 9.6181 17.5757 8.62415 17.5757H2.56353C2.13929 17.5757 1.81201 17.9151 1.81201 18.3272V29.2363C1.81201 29.6484 2.15141 29.9878 2.56353 29.9878Z" fill="black"/>
              <path d="M22.5637 31.5151H0.745457C-0.248486 31.5151 -0.248486 33.0303 0.745457 33.0303H22.5637C23.5577 33.0303 23.5577 31.5151 22.5637 31.5151Z" fill="black"/>
              <path d="M11.121 18.8607C11.1938 18.9334 11.2665 18.994 11.3635 19.0304C11.5574 19.091 11.7513 19.091 11.9453 19.0304C12.0301 18.994 12.115 18.9334 12.1877 18.8607C12.2604 18.7879 12.321 18.7031 12.3574 18.6182C12.4301 18.4243 12.4301 18.2304 12.3574 18.0364C12.321 17.9394 12.2604 17.8667 12.1877 17.794C11.9816 17.5758 11.6422 17.5152 11.3635 17.6243C11.2665 17.6607 11.1938 17.7213 11.121 17.794C11.0483 17.8667 10.9877 17.9394 10.9513 18.0364C10.8665 18.2304 10.8665 18.4243 10.9513 18.6182C10.9877 18.7031 11.0483 18.7879 11.121 18.8607Z" fill="black"/>
              <path d="M24.1998 12.0122C29.6786 8.63034 27.2665 -0.0121085 20.7453 1.27372e-05C14.2362 1.27372e-05 11.8119 8.63034 17.2907 12.0122C15.9937 12.3879 14.7574 12.9819 13.6422 13.7819C13.3028 14.0243 13.218 14.497 13.4604 14.8364C13.7028 15.1758 14.1756 15.2607 14.515 15.0182C21.1816 10.1213 31.0241 14.8364 31.4726 23.0304H25.3392C24.3453 23.0304 24.3453 24.5455 25.3392 24.5455H32.2483C32.6726 24.5455 32.9999 24.2062 32.9999 23.794C32.9999 18.2304 29.2665 13.5152 24.1756 12.0243L24.1998 12.0122ZM15.7513 6.50912C15.9574 -0.0969572 25.5453 -0.0969572 25.7513 6.50912C25.5453 13.1152 15.9574 13.1152 15.7513 6.50912Z" fill="black"/>
            </svg>
            <p class="title"></p>
          </div>
          <div class="body-container">
            <a class="link-one"></a>
            <a class="link-two"></a>
            <a class="link-three"></a>
          </div>
        </div>
      </div>
    `
  }

  static get observedAttributes() {
    return ["data"];
  }

  constructor() {
    super(SingleCardBorderedTop)
    this._clickHandler = this._clickHandler.bind(this);

    this.topBorder = this.shadowRoot.querySelector('.top-border')
    this.cardTitle = this.shadowRoot.querySelector('.title')
    this.bodyContainer = this.shadowRoot.querySelector('.body-container')
    this.linkOne = this.shadowRoot.querySelector('.link-one')
    this.linkTwo = this.shadowRoot.querySelector('.link-two')
    this.linkThree = this.shadowRoot.querySelector('.link-three')
    this.relatedSearchIcon = this.shadowRoot.querySelector('.related-search-svg')
    this.magnifyGlassIcon = this.shadowRoot.querySelector('.magnify-glass-svg')

    this.shadowRoot.addEventListener("click", this._clickHandler);

  }

  attributeChangedCallback(attr, oldVal, newVal) {
    super.attributeChangedCallback(attr, oldVal, newVal);

    switch(attr) {
      case "data":

        this.data = JSON.parse(newVal);
        break;
    }
  }

  _clickHandler(event) {
    if (this.hasAttribute("prevent-navigation")) {
      event.preventDefault();
    }
    
    const target = event.target;
    if (target.tagName.toLowerCase() !== 'a') {
      return;
    }

    const url = new URL(event.target.href);
    const params = new URLSearchParams(url.search);
    const q = target?.innerHTML || ""



    this.emitEvent(SingleCardBorderedTop.events.ClickEvent, {
      detail: {
        q
      }
    });
  }


}

PFElement.create(SingleCardBorderedTop);

export default SingleCardBorderedTop;