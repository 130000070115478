import PFElement from "@patternfly/pfelement";
import "@patternfly/pfe-datetime";

const PRODUCT_CHAR_MAX = 35;

class ResultItemNoLink extends PFElement {
  static get tag() {
    return "result-item"
  }

  static get properties() {
    return {
      truncateLimit: {
        type: Number,
        default: 256
      }
    }
  }

  static get events() {
    return { ClickEvent: `${this.tag}:click` }
  }

  set data(data) {
    this.resultTitle.textContent = data?.cardData?.allTitle ? data?.cardData?.allTitle : data?.cardData?.name ? data?.cardData?.name : data?.cardData?.title
    this.resultTitle.href = data?.cardData?.view_uri ? data?.cardData?.view_uri : data?.cardData?.id
    this.recommendedContainer.setAttribute("style", "display: none;")
    this.keySvg.setAttribute("style", "display: none")
    this.checkSvg.setAttribute("style", "display: none")
    this.banSvg.setAttribute("style", "display: none")

    let recommended = data?.cardData[`[elevated]`]
    if(recommended){
      this.recommendedText.textContent = recommended ? 'Recommended:' : ''
      this.recommendedContainer.setAttribute("style", "display: flex;")
      this.checkSvg.setAttribute("style", "display: block")
      this.checkSvg.setAttribute("style", "width: 15px")
      this.checkSvg.setAttribute("style", "height: 15px")
    }

    let subscription = data?.cardData?.requires_subscription
    if(subscription && !data?.isAuthenticated){
      this.recommendedText.textContent = subscription ? 'Subscription Required:' : ''
      this.recommendedContainer.setAttribute("style", "display: flex;")
      this.keySvg.setAttribute("style", "display: block")
      this.keySvg.setAttribute("style", "width: 15px")
      this.keySvg.setAttribute("style", "height: 15px")
    }
    //TODO: figure out complete logic for displaying unpublished text and "draft" state
    let unpublished = data?.cardData?.ModerationState == "draft" 
    if(unpublished && !subscription){
      this.recommendedText.textContent = unpublished ? 'Unpublished:' : ''
      this.recommendedContainer.setAttribute("style", "display: flex;")
      this.banSvg.setAttribute("style", "display: block")
      this.banSvg.setAttribute("style", "width: 15px")
      this.banSvg.setAttribute("style", "height: 15px")
    }

    let truncatedString = data?.cardData?.highlightedText ? data?.cardData?.highlightedText[0] : data?.cardData?.abstract

    if (truncatedString && truncatedString.length > 200) {
      truncatedString = `${truncatedString.substring(0, 200)}...`
    }

    this.resultDescription.innerHTML = truncatedString;
    if(data?.cardData?.documentKind === 'Solution') {
      this.resultDescription.innerHTML = truncatedString || data?.cardData?.snippet || data?.cardData?.abstract;
    }

    let date = data?.cardData?.lastModifiedDate

    let consoleResultText
    if (data?.cardData?.documentKind === "ModuleDefinition") {
      consoleResultText = "console.redhat.com";
      this.consoleText.innerHTML = consoleResultText;
      this.resultDate.setAttribute("style", "display: none");
    }

    if (date && !consoleResultText) {
      setTimeout(() => this.resultDate?.setDate(new Date(date)), 0);
    }

    let metaDataText;

    if (data?.cardData?.documentKind) {
      if(data?.cardData?.documentKind === "BlogPost") {
        metaDataText = "Blog Post";
      } else if(data?.cardData?.documentKind === "LearningPath"){
        metaDataText = "Learning Path";
      } else if(data?.cardData?.documentKind === "LearningPathResource"){
        metaDataText = "Learning Path Resource";
      } else if(data?.cardData?.documentKind === "article"){
        metaDataText = "Article";
      } else if(data?.cardData?.documentKind === "documentation"){
        metaDataText = "Documentation";
      } else if(data?.cardData?.documentKind === "solution"){
        metaDataText = "Solution";
      } else if(data?.cardData?.documentKind === "ContainerProductListing"){
        metaDataText = "Container Product";
      } else if(data?.cardData?.documentKind === "LabInfo"){
        metaDataText = "Labs";
      } else if(data?.cardData?.documentKind === "ContainerRepository"){
        metaDataText = "Container Repository";
      } else if(data?.cardData?.documentKind === "ModuleDefinition"){
        metaDataText = "";
      } else {
        metaDataText = data?.cardData?.documentKind;
      }
      if(date){
        this.resultMetadata.textContent = `${metaDataText} - `;
      } else {
        this.resultMetadata.textContent = `${metaDataText}`;
      }

      
    }
    let product = data?.cardData?.standard_product?.[0];
    let truncatedProduct;
    if(product){
      truncatedProduct = product?.length > PRODUCT_CHAR_MAX ? `${product?.substring(0, PRODUCT_CHAR_MAX)}&hellip;` : product;
      this.product = product;    
    } else {
      this.resultProduct.setAttribute("style", "display: none;");
    }

    let productTagText = data?.cardData?.product?.[0] ? truncatedProduct : ""
    if(productTagText == "") {
      this.toolTip.setAttribute("hidden", "true !important");
      this.toolTipContent.setAttribute("style", "display: none");
      this.resultProductNameForToolTip.setAttribute("style", "display: none");
    }

    if(product?.length > PRODUCT_CHAR_MAX){
      this.toolTip.setAttribute("style", "display: inline-block")
      this.toolTipContent.textContent = product 
      this.resultProductNameForToolTip.innerHTML = productTagText

    }
    if(product?.length <= PRODUCT_CHAR_MAX) {
      this.resultProductName.textContent = productTagText
    }
    
    this.resultProductName.setAttribute("style", "display: none")
    this.toolTip.setAttribute("style", "display: none")
  }

  

  get html() {
    return `
    <style>
      :host{ display:block }
      .result-card{
        padding: 16px;
        margin-bottom: 32px;
      }

      mark {
        background-color: #FDF7E7
      }

      .title{
        color: #0066CC;
        font-family: 'Red Hat Display';
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        margin: 0;
        letter-spacing: 0.5px;
        text-decoration: none;
      }

      .meta-data-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 16px 0;
      }

      .recommended-container {
        display: flex;
        align-items: center;
      }

      .recommended {
        font-family: 'Red Hat Display';
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: #4E5151;
        margin: 0 4px 0 8px;
      }

      .metadata, .product-name{
        font-family: 'Red Hat Display';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #6A6E73;
        margin: 0;
        display: inline;
      }

      .console-text{
        font-family: 'Red Hat Display';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #6A6E73;
        margin: 0;
        display: inline;
      }

      pfe-datetime{
        width: -moz-fit-content;
        font-family: 'Red Hat Display';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #6A6E73;
        margin: 0;
        display: inline;
        margin-left: 4px;
        margin-right: 8px;
      }

      .description{
        color: #000000;
        font-size: 16px;
        line-height: 21px;
        margin: 0;
      }
      
      .tooltip {
        position: relative;
      }
      
      .tooltip .tooltiptext {
        visibility: hidden;
        width: max-content;
        background-color: black;
        color: #fff;
        text-align: center;
        padding: 12px 16px;
        margin: 12px;
        /* Position the tooltip */
        position: absolute;
        z-index: 1;
        bottom: 100%;
        left: 0;
        // margin-left: -60px;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.14px;
        color: #FFFFFF;
      }
      
      .tooltip:hover .tooltiptext {
        visibility: visible;
      }

      .tooltip .tooltiptext::after {
        content: " ";
        position: absolute;
        top: 100%; /* At the bottom of the tooltip */
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
      }

      .product-name-tooltip{
        margin: 0;
      }

      .product {
        display: inline-block;
        border: 1px solid #B8BBBE;
        border-radius: 14.5px;
        background-color: #f5f5f5;
        padding: 1px 5px;
        margin: 15px 0;
        font-size: 12px;
      }

      [hidden] {
        display: none;
      }
     
      @media (max-width: 768px) {
        .result-card{
          margin-bottom: 16px;
        }
        .description {
          overflow-wrap: anywhere;
        }
        .tooltip:hover .tooltiptext {
          visibility: hidden;
        }
      }
       @media (max-width: 480px) {
        .result-card{
          margin-bottom: 0;
          padding-left: 24px;
        }
        .description{
          font-size: 14px;
        }
        .meta-data-container {
          padding: 8px 0 16px 0;
        }
      }


      /* seperator 'line' after result */
      .result-card-seperator {
        border-bottom: 1px solid #d2d2d2;
      }
    </style>
      <div class="result-card">
        <a class="title"></a>
        <div class="meta-data-container">
          <div class="recommended-container">
            <div class="svg-container"></div>
            <svg data-testid="recommended-icon" aria-hidden="true" class="key-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#4E5151"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M336 352c97.2 0 176-78.8 176-176S433.2 0 336 0S160 78.8 160 176c0 18.7 2.9 36.8 8.3 53.7L7 391c-4.5 4.5-7 10.6-7 17v80c0 13.3 10.7 24 24 24h80c13.3 0 24-10.7 24-24V448h40c13.3 0 24-10.7 24-24V384h40c6.4 0 12.5-2.5 17-7l33.3-33.3c16.9 5.4 35 8.3 53.7 8.3zm40-176c-22.1 0-40-17.9-40-40s17.9-40 40-40s40 17.9 40 40s-17.9 40-40 40z"/></svg>
            <svg data-testid="check-icon" aria-hidden="true" class="check-svg" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_568_7001)">
              <path d="M1.49995 14.43H13.4999C13.6226 14.432 13.7445 14.4094 13.8582 14.3633C13.972 14.3173 14.0753 14.2489 14.1621 14.1621C14.2488 14.0754 14.3173 13.972 14.3633 13.8583C14.4093 13.7445 14.432 13.6227 14.4299 13.5V9.49501C14.4299 9.24836 14.332 9.01181 14.1576 8.8374C13.9831 8.66299 13.7466 8.56501 13.4999 8.56501C13.2533 8.56501 13.0167 8.66299 12.8423 8.8374C12.6679 9.01181 12.5699 9.24836 12.5699 9.49501V12.57H2.42995V2.43001H9.49495C9.7416 2.43001 9.97815 2.33203 10.1526 2.15762C10.327 1.98321 10.425 1.74666 10.425 1.50001C10.425 1.25336 10.327 1.01681 10.1526 0.842401C9.97815 0.667992 9.7416 0.57001 9.49495 0.57001H1.49995C1.37726 0.567982 1.25542 0.590652 1.14167 0.636671C1.02792 0.682689 0.924587 0.751116 0.837821 0.837882C0.751055 0.924648 0.682628 1.02798 0.63661 1.14173C0.590591 1.25548 0.567921 1.37732 0.569949 1.50001V13.5C0.567921 13.6227 0.590591 13.7445 0.63661 13.8583C0.682628 13.972 0.751055 14.0754 0.837821 14.1621C0.924587 14.2489 1.02792 14.3173 1.14167 14.3633C1.25542 14.4094 1.37726 14.432 1.49995 14.43Z" fill="#EE0000"/>
              <path d="M6.16488 6.84C5.30988 5.97 3.95987 7.32 4.82987 8.16L6.83987 10.17C7.01639 10.3423 7.25324 10.4387 7.49988 10.4387C7.74651 10.4387 7.98336 10.3423 8.15988 10.17L14.1599 4.17C15.0299 3.315 13.6799 1.965 12.8399 2.835L7.49988 8.175L6.16488 6.84Z" fill="#EE0000"/>
              </g>
              <defs>
              <clipPath id="clip0_568_7001">
              <rect width="15" height="15" rx="3" fill="white"/>
              </clipPath>
              </defs>
            </svg>
            <svg data-testid="ban-icon" aria-hidden="true" class="ban-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#4E5151"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM512 256c0 141.4-114.6 256-256 256S0 397.4 0 256S114.6 0 256 0S512 114.6 512 256z"/></svg>
            <p class="recommended"></p>
          </div>
          <div>
            <p class="metadata"></p>
            <pfe-datetime
              datetime=""
              type="local"
              day="numeric"
              month="short"
              year="numeric">
            </pfe-datetime>
            <p class="console-text"></p>
          </div>
          <div>
            <span class="product-title"></span><div class="tooltip"><span class="product-name-tooltip"></span><span class="tooltiptext">Tooltip text</span></div><span class="product-name"></span>
          </div>
        </div>
        <p class="description"></p>
        <span hidden class="product"></span>
      </div>
    `
  }

  static get observedAttributes() {
    return ["data", "is-product-ab-test", "product-ab-test-variant"];
  }

  constructor() {
    super(ResultItemNoLink)
    this.resultTitle = this.shadowRoot.querySelector('.title');
    this.recommendedText = this.shadowRoot.querySelector('.recommended');
    this.recommendedContainer = this.shadowRoot.querySelector('.recommended-container');
    this.resultMetadata = this.shadowRoot.querySelector('.metadata');
    this.resultDescription = this.shadowRoot.querySelector('.description');
    this.resultProduct = this.shadowRoot.querySelector(".product");
    this.resultDate = this.shadowRoot.querySelector('pfe-datetime');
    this.consoleText = this.shadowRoot.querySelector('.console-text');
    this.resultProductName = this.shadowRoot.querySelector('.product-name');
    this.resultProductNameForToolTip = this.shadowRoot.querySelector('.product-name-tooltip');
    this.toolTip = this.shadowRoot.querySelector('.tooltip');
    this.toolTipContent = this.shadowRoot.querySelector('.tooltiptext');
    this.resultSvg = this.shadowRoot.querySelector('.svg-container');
    this.keySvg = this.shadowRoot.querySelector('.key-svg');
    this.checkSvg = this.shadowRoot.querySelector('.check-svg');
    this.banSvg = this.shadowRoot.querySelector('.ban-svg');
  }


  attributeChangedCallback(attr, oldVal, newVal) {
    super.attributeChangedCallback(attr, oldVal, newVal);

    // eslint-disable-next-line default-case
    switch(attr) {
      case "data":
        this.data = JSON.parse(newVal);
        break;
      case "is-product-ab-test":
        break;
    }
    if(attr === "product-ab-test-variant") {
      this.productABTestVariant = this.getAttribute("product-ab-test-variant");
       // Only show for 'a' or 'b' test - not control
       if((this.productABTestVariant === 'a') || (this.productABTestVariant === 'b')) {
         this.resultProduct.innerHTML = this.product;
         this.resultProduct.removeAttribute("hidden");
       } else {
         this.resultProduct.setAttribute("hidden", "true");
       }
       if(this.productABTestVariant === 'b') {
        // Show separater line
        this.shadowRoot.querySelector('.result-card').classList.add("result-card-seperator");
       }
    }
  }

}

PFElement.create(ResultItemNoLink);

export default ResultItemNoLink;