export async function getSession() {
  return new Promise((resolve, reject) => {
    let session = window?.portal?.primer?.session;
    session?.onInit(() => {
      if (!session.isAuthenticated()) {
        resolve(false);
      } else {
        resolve({
          user: session?.getUserInfo(),
          token: session?.getEncodedToken(),
        });
      }
    });
  });
}

export async function fetchLoggedInData(endpoint, token) {
  try {
    const response = await fetch(endpoint, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return error;
  }
}
