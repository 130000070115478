import { Skeleton } from "@patternfly/react-core"

export class SolrSortFields {
  static sortFields = [
    { select: "Most Relevant", queryParam: "relevant", isDefault: true },
    { select: "Newest First", queryParam: "lastModifiedDate desc" },
    { select: "Oldest First", queryParam: "lastModifiedDate asc" },
  ];
}


export function renderSkeleton (numRows) {
  let skeletonItem
    let arr = Array.from('x'.repeat(numRows))
    return arr.map(num => {
      skeletonItem = <div className="single-skeleton-container">
      <Skeleton className="skeleton-item" fontSize="sm" width="55%" />
      <Skeleton className="skeleton-item" fontSize="sm" width="35%" />
      <Skeleton className="skeleton-description-item" fontSize="sm" width="95%" />
      <Skeleton fontSize="sm" width="95%" />
      </div>
      return skeletonItem
    })
} 

export const trendingSearchesData = [{
  //TODO: once API is avail, replace this with actual data
  title: "Top trending searches",
  0: {text: "link1", link: "https://access.redhat.com/products"},
  1: {text: "link2", link: "https://access.redhat.com/security"},
  2: {text: "link3", link: "https://access.redhat.com/community"},

}]

export const topPagesData = {
  title: "Top pages",
  0: {text: "Products and Documentation", link: "https://access.redhat.com/products"},
  1: {text: "Security", link: "https://access.redhat.com/security"},
  2: {text: "Community", link: "https://access.redhat.com/community"},

}

export function generateTroubleshootingData (query) {
  return {title: "Troubleshoot your issue",
  content: "Find solutions to your problems quickly with Red Hat Support's troubleshooting tool.",
  buttonContent: {text: "Troubleshoot now", link: `https://access.redhat.com/support/cases/#/troubleshoot?summary=${encodeURIComponent(query)}`}
  }
}

export function renderPromiseValues(values){
  let intentData = [{
    intent: values?.[1]?.intent,
    confidenceScore: values?.[1]?.confidence_score
  }]


  let oneBoxData
  if (values?.[2]?.length == 0) {
    oneBoxData = []
  } else {
    oneBoxData = [{
      title: values?.[2]?.name,
      description: values?.[2]?.description,
      links: values?.[2]?.links
    }]
  }


  let relatedSearchData = values?.[3]
  let topDocData = values?.[4]
  return [intentData, oneBoxData, relatedSearchData, topDocData]
}