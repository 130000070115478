export const setUpABTest = (attr: string): { active: boolean, variant: string } => {
  const html: HTMLHtmlElement = document.querySelector("html")!;
  let isABTest: boolean = false;
  let activeVariant: string = '';

  if (html.hasAttribute(attr)) {
    isABTest = true;
    activeVariant = html.getAttribute('data-variant') || activeVariant;
  } else {
    var observer = new MutationObserver(function (mutationList) {
      mutationList.forEach(function (mutation) {
        switch (mutation.type) {
          case "attributes":
            switch (mutation.attributeName) {
              case attr:
                isABTest = true;
                activeVariant = html.getAttribute('data-variant') || activeVariant;
                break;
              default:
                break;
            }
        }
      });
    });
    observer.observe(html, {
      attributeFilter: [attr],
    });
  }
  return { active: isABTest, variant: activeVariant };
};
