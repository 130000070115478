import React from 'react';
import { Button } from '@patternfly/react-core';
import '../../assets/ErrorIcon.svg'
import './ErrorState.css'

export default function EmptyStateComponent(props) {
return (
  <div className="error-state__wrapper">
    <svg data-testid="error-state-icon" aria-hidden="true" className="error-state-svg" width="69" height="64" viewBox="0 0 69 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1100_25426)">
        <path d="M15.6485 31.9997C15.6485 23.8492 9.83034 16.9526 1.80526 15.6235C-0.226087 15.3226 -0.727655 18.357 1.27861 18.7081C16.125 21.2661 16.125 42.7332 1.27861 45.2912C-0.577184 45.5921 -0.351479 48.426 1.5294 48.4009C9.65479 47.2473 15.6485 40.2003 15.6485 31.9997Z" fill="#EE0000"/>
        <path d="M25.6798 32.0001C25.6798 21.3919 19.4102 11.812 9.70485 7.57376C7.82397 6.74618 6.57005 9.60511 8.45093 10.4327C27.1092 18.3825 27.1092 45.5926 8.45093 53.5424C6.57005 54.37 7.82397 57.2289 9.70485 56.4013C19.4102 52.1631 25.6798 42.5832 25.6798 31.975V32.0001Z" fill="#EE0000"/>
        <path d="M67.0094 45.2912C52.163 42.7332 52.163 21.2661 67.0094 18.7081C67.862 18.5577 68.4388 17.7551 68.2884 16.9025C68.1379 16.0498 67.3354 15.473 66.4827 15.6235C48.1755 18.7834 48.2006 45.216 66.4827 48.4009C68.489 48.7269 69.0407 45.6674 67.0094 45.3163V45.2912Z" fill="#EE0000"/>
        <path d="M59.8373 53.5422C41.179 45.5924 41.179 18.3824 59.8373 10.4325C61.7181 9.63001 60.4642 6.72092 58.5833 7.57358C37.4423 16.6018 37.4423 47.4231 58.5833 56.4262C60.4391 57.2288 61.7181 54.3949 59.8373 53.5673V53.5422Z" fill="#EE0000"/>
        <path d="M52.4894 61.0411C30.2951 48.527 30.2951 15.4487 52.4894 2.93455C53.2418 2.50822 53.4926 1.53016 53.0662 0.802887C52.6399 0.0505361 51.6869 -0.200248 50.9346 0.226085C42.759 4.9659 36.8155 12.665 34.1571 21.4925C31.4738 12.6399 25.5553 4.9659 17.3797 0.226085C15.5992 -0.77705 14.0443 1.88126 15.7998 2.93455C37.9941 15.4487 37.9941 48.527 15.7998 61.0411C14.0192 62.0693 15.5992 64.7778 17.3797 63.7496C25.5553 59.0098 31.4988 51.3107 34.1571 42.4831C36.8155 51.3107 42.7339 59.0098 50.9346 63.7496C52.6901 64.7778 54.27 62.0693 52.5145 61.0411H52.4894Z" fill="#EE0000"/>
      </g>
      <defs>
        <clipPath id="clip0_1100_25426">
          <rect width="68.3135" height="64" fill="white"/>
        </clipPath>
      </defs>
    </svg>
    <div className='error-state__text-content'>
      <p className='error-state__text'>{props.titlePrimary}</p>
      <p className='error-state__text'>{props.titleSecondary}</p>
    </div>
    <div className='error-state__button-container'>
      <Button component="a" href="/" variant='secondary'>{props.buttonText}</Button>
    </div>
  </div>
)
}