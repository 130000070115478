import { useEffect, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { generateTroubleshootingData } from "../utils/Utils";

export default function ResultCards({
  resultCardData,
  resultCardAllListings,
  inputValue,
  resultItemClicked,
  isAuthenticated,
  isProductABTest,
  productABTestVariant
}) {
  const ref = useRef(null);
  const troubleshootRef = useRef(null);
  const popularRef = useRef(null);
  const history = useHistory();
  const url = new URLSearchParams(window.location.search);

  const buildContentListingItemClickedEvent = useCallback(
    (e, resultCardType) => {
      if (!e.detail || !e.detail.q) {
        return;
      }

      const link = e.detail.q;
      //find the data for the specific link clicked
      let linkClicked;
      resultCardAllListings?.forEach((listing) => {
        if (
          listing?.displayFeature === resultCardType &&
          listing?.contentTitle === link
        ) {
          linkClicked = listing;
          //@ts-ignore
          window.appEventData = window.appEventData || [];
          //@ts-ignore
          appEventData.push({
            event: "Content Listing Item Clicked",
            linkInfo: {
              displayPosition: `${linkClicked.contentPosition}`,
              category: linkClicked.displayFeature,
              text: `${linkClicked.contentTitle}`,
              href: `${linkClicked.contentUrl}`,
              linkTypeName: "search result click",
              targetHost: `${window.location.host}`,
            },
            listingItemClicked: {
              listingType: "content",
              listing: [
                {
                  content: {
                    displayFeature: `${linkClicked.displayFeature}`,
                    displayFeatureTitle: `${linkClicked.displayFeatureTitle}`,
                    contentTitle: `${linkClicked.contentTitle}`,
                    contentUrl: `${linkClicked.contentUrl}`,
                    contentID: `${linkClicked.contentUrl}`,
                    contentPositionRelative: `${linkClicked.contentPositionRelative}`,
                    contentPosition: `${linkClicked.contentPosition}`,
                  },
                },
              ],
            },
          });
        }
      });
    },
    [resultCardAllListings],
  );

  useEffect(() => {
    if (!ref || !ref.current) {
      return;
    }

    //@ts-ignore
    ref.current?.addEventListener("single-card-bordered-top:click", (e) => {
      if (!e.detail || !e.detail.q) {
        return;
      }

      const q = e.detail.q;
      buildContentListingItemClickedEvent(e, "People Also Searched");
      history.push(`?q=${q}`);
    });
  }, [history, buildContentListingItemClickedEvent]);

  useEffect(() => {
    if (!troubleshootRef || !troubleshootRef.current) {
      return;
    }
    //@ts-ignore
    troubleshootRef.current?.addEventListener(
      "troubleshooting-card:click",
      (e) => {
        buildContentListingItemClickedEvent(e, "Troubleshoot");
      },
    );
  }, [buildContentListingItemClickedEvent]);

  useEffect(() => {
    if (!popularRef || !popularRef.current) {
      return;
    }

    //@ts-ignore
    popularRef.current?.addEventListener(
      "single-card-bordered-top:click",
      (e) => {
        buildContentListingItemClickedEvent(e, "Popular With Similar Users");
      },
    );
  }, [buildContentListingItemClickedEvent]);

  return (
    <div className="result-card-wrapper">
      {resultCardData?.map((cardData, index) => {
        let resultCardItem;
        if (cardData.displayFeature == "Troubleshoot") {
          resultCardItem = (
            <troubleshooting-card
              ref={troubleshootRef}
              data={JSON.stringify(
                generateTroubleshootingData(inputValue),
              )}></troubleshooting-card>
          );
        }
        if (cardData.displayFeature == "Natural") {
          resultCardItem = (
            <result-item
              key={cardData.id}
              data-testid={"result-item"}
              data={JSON.stringify({ cardData, isAuthenticated })}
              is-product-ab-test={isProductABTest}
              product-ab-test-variant={productABTestVariant}
              onClick={() => resultItemClicked(cardData, index)}></result-item>
          );
        }
        if (cardData.displayFeature == "People Also Searched") {
          resultCardItem = (
            <single-card-bordered-top
              ref={ref}
              data={JSON.stringify(cardData)}
              prevent-navigation></single-card-bordered-top>
          );
        }
        //TODO- only want to show this on the first page
        if (
          cardData.displayFeature == "Popular With Similar Users" &&
          url.get("p") == "1"
        ) {
          resultCardItem = (
            <single-card-bordered-top
              ref={popularRef}
              data={JSON.stringify(cardData)}></single-card-bordered-top>
          );
        }

        return resultCardItem;
      })}
    </div>
  );
}
