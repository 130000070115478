import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

export default function Suggestions({ suggestionStateData, topPagesData, trendingSearchesData }) {
  const ref = useRef(null);
  const history = useHistory();


  useEffect(() => {
    if (!ref || !ref.current) {
      return;
    }

    //@ts-ignore
    ref.current?.addEventListener("suggestion-state:click", e => {
      if (!e.detail || !e.detail.q) {
        return;
      }
      
      const q = e.detail.q;
      history.push(`?q=${q}`);
    })
  }, [history]);

  const handleClick = (event:MouseEvent) => {
    event.preventDefault();
  }
  
  return (
    <div className="suggestion-state-wrapper" ref={ref}>
      <suggestion-state data={JSON.stringify(suggestionStateData)} onClick={handleClick}></suggestion-state>
      <p className="troubleshoot-text">Or <a href="https://access.redhat.com/support/cases/#/troubleshoot">troubleshoot your issue</a></p>
      <div className="double-card-bordered-top-container">
        {/* rendering single card until trending searches endpoint is complete */}
        {/* <double-card-bordered-top data={JSON.stringify({ trendingSearchesData, topPagesData })}></double-card-bordered-top> */}
        <single-card-bordered-top data={JSON.stringify(topPagesData)}></single-card-bordered-top>
      </div>
    </div>
  );
}