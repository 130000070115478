import { ExpandableSection } from "@patternfly/react-core";
import DropdownSort from "../DropdownSort/DropdownSort";
import { renderSkeleton, SolrSortFields } from "../utils/Utils";

export default function LoadingState(props) {
  const currentSort =
  props.sortFields?.find(
    (sortField) => sortField?.queryParam === props.activeSort
  )?.select || "Most Relevant";
  return (
    <>
    <div className="total-results-container">
              <div className="total-results-sort-by-container">
                <div className="num-results-container" data-testid='total-results'>
                  <p className="total-results-heading-text">Search Results</p>
                </div>
                <div className="dropdown-sort-container">
                  <p
                  data-testid="sort-text" className="dropdown-sort-text" aria-hidden="true">Sort by:</p>
                  <span 
                  data-testid="sort-by" className="visually-hidden">Sort By: {currentSort}</span>
                  <DropdownSort
                    sortFields={SolrSortFields.sortFields}
                    isPlain
                    showSort={true}
                  />
                </div>
              </div>
              <div className="filters-container-mobile">
                <ExpandableSection
                  toggleText={"FILTER BY"}
                />
              </div>
            </div>
    <div className="loading">
      <div className="loading-container">{renderSkeleton(props.rows)}</div>
    </div>
  </>
  );
}