import React from "react";
import Filter from "../FilterSelect/FilterSelect";
import { Accordion } from "@patternfly/react-core";

interface IFilterProps {
  productsSelectedItemsArray: string[];
  contentTypesSelectedItemsArray: string[];
  productOptions: string[];
  contentTypeOptions: string[];
  onProductUpdate: (newSelectedOptions: string[], product: string) => any;
  onContentTypeUpdate: (
    newSelectedOptions: string[],
    contentType: string,
  ) => any;
  onUpdateVersions?: (
    versionsSelected: string[],
    currProduct: string,
    allVersions: string[],
    selection?: string,
  ) => any;
  activeProductVersions: any[];
  activeContentTypeFilter: string[];
  productVersions: any[];
  productKeyProp: string;
  contentTypeKeyProp: string;
  isDisabled: boolean;
  isMobile: boolean;
}

export default function Filters(props: IFilterProps) {
  return (
    <Accordion asDefinitionList isBordered>
      <>
        <Filter
          id={`products-filter${props.isMobile ? "-mobile" : ""}`}
          heading={"Products"}
          selectedItemsArray={props.productsSelectedItemsArray}
          options={props.productOptions}
          onUpdate={props.onProductUpdate}
          onUpdateVersions={props.onUpdateVersions}
          additionalInfoPopover={undefined}
          disableCollapse={undefined}
          toggleText={"Products"}
          productVersions={props.productVersions}
          activeProductVersions={props.activeProductVersions}
          contentTypes={props.activeContentTypeFilter}
          keyProp={props.productKeyProp}
          isDisabled={props.isDisabled}
          isMobile={props.isMobile}
        />
      </>
      <>
        <Filter
          id={`content-type-filter${props.isMobile ? "-mobile" : ""}`}
          heading={"Content Type"}
          selectedItemsArray={props.contentTypesSelectedItemsArray}
          options={props.contentTypeOptions}
          onUpdate={props.onContentTypeUpdate}
          additionalInfoPopover={undefined}
          disableCollapse={true}
          toggleText={"Content type"}
          productVersions={[]}
          activeProductVersions={[]}
          contentTypes={[]}
          keyProp={props.contentTypeKeyProp}
          isDisabled={props.isDisabled}
          isMobile={props.isMobile}
        />
      </>
    </Accordion>
  );
}
