import React from "react";
import {
  Button,
  Checkbox,
  Select,
  SelectOption,
  SelectVariant,
  Divider,
} from "@patternfly/react-core";
import take from "lodash/take";
import "./VersionSelect.css";

export default class CheckboxSelectInput extends React.Component {
  constructor(props) {
    super(props);
    let index = this.props.versionFromUrl?.indexOf(this.props.product);

    const url = new URL(window.location.href);
    let docVersionsFromUrl = url.searchParams
      ?.get("documentation_version")
      ?.split("&");

    let currVersions;
    docVersionsFromUrl?.forEach((product, index) => {
      if (product == props?.currItem) {
        currVersions = docVersionsFromUrl[index + 1].split(",");
      }
    });

    let selectedFromProps = this.props?.versionFromUrl[index + 1]
      ?.split(",")
      ?.filter((v) => v !== "");
    this.state = {
      isOpen: false,
      selected: currVersions || [],
      collapse: true,
    };

    this.onToggle = (isOpen) => {
      this.setState({
        isOpen,
      });
    };

    this.setCollapse = () => {
      this.setState((prevState) => ({
        collapse: !prevState.collapse,
      }));
    };

    this.onSelect = (e, selection, item) => {
      const { selected } = this.state;

      if (selected.includes(selection)) {
        this.setState(
          (prevState) => ({
            selected: prevState.selected.filter((item) => item !== selection),
          }),
          () =>
            this.props.onUpdateVersions(
              this.state.selected,
              this.props?.product,
              this.props?.options,
              selection,
            ),
        );
      } else {
        this.setState(
          (prevState) => ({ selected: [...prevState.selected, selection] }),
          () =>
            this.props.onUpdateVersions(
              this.state.selected,
              this.props?.product,
              this.props?.options,
              selection,
            ),
        );
      }
    };

    this.clearSelection = () => {
      this.setState({
        selected: [],
      });
    };

    this.optionsReversed = [];
    for (let i = this.props?.options?.length - 1; i >= 0; i--) {
      this.optionsReversed.push(this?.props?.options[i]);
    }

    this.needToExpandList = this.optionsReversed.length > 2;
  }

  render() {
    const { isOpen, selected } = this.state;
    const titleId = "checkbox-select-id";
    const optionsToRender = this.state.collapse
      ? take(this.optionsReversed, 2)
      : this.optionsReversed;

    return (
      <div>
        {optionsToRender?.map((o, index) => {
          return (
            <Checkbox
              className="nested facet-filter-wrapper"
              key={`version-${o?.[0]}`}
              id={`id-version-${o?.[0]}`}
              name={o?.[0]}
              label={
                <span className="facet-filter-text-wrapper">
                  <span className="facet-filter-title">{o?.[0]}</span>
                  <span className="facet-filter-num-results">{o?.[1]}</span>
                </span>
              }
              aria-label={`${o?.[0]}-checkbox-item-${index}`}
              isChecked={selected?.includes(o?.[0])}
              onChange={(e) => this.onSelect(e, o?.[0], this.props.currItem)}
              isDisabled={this.props.isDisabled}
            />
          );
        })}
        {this.needToExpandList && (
          <Button
            className="expand-button-version"
            variant="link"
            isInline
            onClick={() => this.setCollapse()}>
            {this.state.collapse && this.optionsReversed.length > 2
              ? "See more"
              : "See less"}
          </Button>
        )}
      </div>
    );
  }
}
