import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem
} from "@patternfly/react-core";
import { CheckIcon, FilterIcon } from "@patternfly/react-icons";
import "./DropdownSort.css";


export default function DropdownSort(props) {
  const [isOpen, setIsOpen] = useState(false);

  function onSelect(e) {
    if (!e) {
      return;
    }
    updateParentSortParams(e.getAttribute("value") || "");
    onToggle(e);
  }

  function updateParentSortParams(sortOption) {
    props.onSortChange(`${sortOption}`);
  }

  function onToggle(e) {
    setIsOpen(!isOpen);
  }

  const currentSort =
    props.sortFields?.find(
      (sortField) => sortField?.queryParam === props.activeSort
    )?.select || "Most Relevant";

  const dropdownItems = props.sortFields?.map((index, key) => {
    return (
      <>
        <DropdownItem
          key={`index-${index.select}-${key}`}
          value={index.queryParam}
          component="button"
        >
          {index.select}{" "}
          {index.select === currentSort ? (
            <CheckIcon color="#06c"/>
          ) : null}
        </DropdownItem>
      </>
    );
  });

  return (
    <>
      <div className="cpsearch-sort-options">
        <div className="cpsearch-sort-container">
          {props.showSort && (
            <Dropdown
              className="cpsearch-sort-dropdown"
              key={"cpsearch-sort-dropdown"}
              isOpen={isOpen}
              dropdownItems={dropdownItems}
              onSelect={(e) => onSelect(e?.target)}
              toggle={
                <DropdownToggle onToggle={(e) => onToggle(e)}>
                  <span key={`cpsearch-sort-item-${currentSort}`} className="cpsearch-sort-dropdown__text">{currentSort}</span>
                </DropdownToggle>
              }
            />
          )}
        </div>
      </div>
    </>
  );
}
