import React, { useState } from "react";
import { Checkbox, Button } from "@patternfly/react-core";
import get from "lodash/get";
import take from "lodash/take";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import CheckboxSelectInput from "./VersionSelect";
import "./FilterCheckList.css";

interface IFilterCheckListProps {
  options: string[];
  searchString: string;
  selectedOptions: string[];
  onUpdate: (newSelectedOptions: string[], value: string) => any;
  onUpdateVersions?: (
    versionsSelected: string[],
    currProduct: string,
    allVersions: string[],
  ) => any;
  clearSearchStr: () => any;
  disableCollapse?: Boolean;
  productVersions: any[];
  activeProductVersions: any[];
  contentTypes: any[];
  isDisabled: boolean;
  isMobile: boolean;
}

export default function FilterCheckList(props: IFilterCheckListProps) {
  const [collapse, setCollapse] = useState(true);
  const allOptions = props.options;
  const filteredOptions = sortByInput(
    props.searchString,
    filter(
      allOptions,
      (item) =>
        (item?.heading || "")
          .toLowerCase()
          .indexOf((props.searchString || "").toLowerCase()) !== -1,
    ),
  );
  const optionsToRender =
    !props.disableCollapse && collapse
      ? take(filteredOptions, 5)
      : filteredOptions;

  const needToExpandList =
    !props.disableCollapse && get(filteredOptions, "length") > 5;

  if (get(props.options, "length") < 1) return null;

  const handleOptionsChange = (value: string) => {
    let valueAsStr = `"${value}"`;
    const index = props.selectedOptions.indexOf(valueAsStr);
    const s = props.selectedOptions;

    //if index isn't found in array of selected options, add it
    //otherwise, remove it from the array
    if (index === -1) {
      s.push(valueAsStr);
    } else {
      s.splice(index, 1);
    }
    props.onUpdate(s, value);
    props.clearSearchStr();
  };

  const isChecked = (value: string) => {
    return props.selectedOptions.indexOf(`"${value}"`) !== -1;
  };

  let isDocumentation = false;
  let contentTypes;
  if (props.contentTypes.length > 0) {
    contentTypes = props.contentTypes.map((p) => p.replaceAll('"', ""));
    isDocumentation = contentTypes.includes("Documentation");
  }
  const url = new URL(window.location.href);
  let documentationVersionsFromUrl =
    url.searchParams
      .get("documentation_version")
      ?.split(/[,&]+/)
      ?.filter((d) => d !== "") || [];

  /**
   * Returns the filteredArray sorted by the options that
   * match the searchString earliest in its name
   * @param searchString
   * @param filteredArray
   */
  function sortByInput(searchString: string, filteredArray: string[]) {
    var matchedFirst = [] as string[];
    var matchedAfter = [] as string[];

    filteredArray.forEach((option) => {
      //@ts-ignore
      (option?.heading || "")
        .toLowerCase()
        .indexOf(searchString?.toLowerCase()) === 0
        ? matchedFirst.push(option)
        : matchedAfter.push(option);
    });

    return matchedFirst.concat(matchedAfter);
  }

  if (isEmpty(optionsToRender)) {
    return (
      <p className="helper-text search-page-filter-checklist">
        No filter found
      </p>
    );
  } else {
    return (
      <>
        <ul className="search-page-filter-checklist pf-c-list">
          {optionsToRender.map((item, index: number) => {
            let itemName;
            if (item?.heading == "LabInfo") {
              itemName = "Labs";
            } else if (item?.heading == "Blog") {
              itemName = "Blogs";
            } else if (item?.heading == "Solution") {
              itemName = "Solutions";
            } else if (item?.heading == "Article") {
              itemName = "Articles";
            } else if (item?.heading == "Discussion") {
              itemName = "Discussions";
            } else if (item?.heading == "Video") {
              itemName = "Videos";
            } else if (item?.heading == "Product") {
              itemName = "Products";
            } else if (item?.heading == "LearningPathResource") {
              itemName = "Learning Path Resource";
            } else if (item?.heading == "LearningPath") {
              itemName = "Learning Path";
            } else {
              itemName = item?.heading;
            }
            let currProductWithVersions;
            let currProduct;
            props.productVersions.map((version) => {
              for (const [key, value] of Object.entries(version)) {
                if (key === item.heading) {
                  currProduct = key;
                  currProductWithVersions = value;
                }
              }
            });
            if (
              (item.heading || "")
                .toLowerCase()
                .indexOf((props.searchString || "").toLowerCase()) !== -1
            ) {
              return (
                <>
                  <li key={index} className="filter-checklist-item-container">
                    <Checkbox
                      className={`filter-checklist-item facet-filter-wrapper`}
                      key={`${index}-${item.heading}${
                        props.isMobile ? `-mobile` : ""
                      }`}
                      id={`id-${item.heading}${
                        props.isMobile ? `-mobile` : ""
                      }`}
                      name={itemName}
                      label={
                        <span className="facet-filter-text-wrapper">
                          <span className="facet-filter-title">{itemName}</span>
                          <span className="facet-filter-num-results">
                            {item.numResults?.toLocaleString()}
                          </span>
                        </span>
                      }
                      aria-label={`${item.heading}-checkbox-item-${index}${
                        props.isMobile ? `-mobile` : ""
                      }`}
                      isChecked={isChecked(item.heading)}
                      value={isChecked(item).toString()}
                      onChange={handleOptionsChange.bind(null, item.solrKey)}
                      isDisabled={props.isDisabled}
                    />
                    {/* render product version dropown if versions exist for current product and product is selected */}

                    {currProductWithVersions?.length > 0 &&
                      isChecked(item.heading) &&
                      isDocumentation && (
                        <CheckboxSelectInput
                          key={`version-${item.heading}`}
                          options={currProductWithVersions}
                          currItem={item.heading}
                          activeVersionFilters={
                            props.activeProductVersions?.length > 0
                              ? props.activeProductVersions
                              : []
                          }
                          versionFromUrl={
                            documentationVersionsFromUrl?.length > 0
                              ? documentationVersionsFromUrl
                              : []
                          }
                          product={currProduct}
                          onUpdateVersions={props.onUpdateVersions}
                          isDisabled={props.isDisabled}
                        />
                      )}
                  </li>
                </>
              );
            } else {
              return null;
            }
          })}
        </ul>
        {needToExpandList && (
          <Button
            className="expand-button"
            variant="link"
            isInline
            onClick={() => setCollapse(!collapse)}>
            {collapse && allOptions.length > 1 ? "See more" : "See less"}
          </Button>
        )}
      </>
    );
  }
}
