export interface IState {
  inputValue: string | null,
  rows: number;
  pageNumber: number;
  startResultIndex: number;
  loading: boolean;
  init: boolean;
  results: any[];
  numResults: number;
  suggestions: any[];
  error: boolean;
  documentKindFilters: any[];
  activeContentTypeFilter: [];
  productFilters: any[];
  documentVersionFilters: any[];
  activeProductFilters: any[];
  activeVersionFilters: any[];
  activeExcludedVersionFilters: any[]
  sort: string;
  intentInfo: any[];
  oneBoxInfo: any[];
  relatedSearches: any[];
  topDocuments: any[];
  popularUser: boolean;
}

export function getInitialSearchState(): IState{
  const url = new URLSearchParams(window.location.search)
  const q = url.get('q');
  const numRows = url.get('rows')
  const page = url.get('p')

  const initialState: IState = {
    inputValue: q?.trim() || "",
    rows: numRows ? parseInt(numRows, 10) : 10,
    pageNumber: page ? parseInt(page, 10) : 1,
    startResultIndex: page && numRows ? (parseInt(page, 10) - 1) * parseInt(numRows, 10) : 0,
    loading: false,
    init: true,
    results: [],
    numResults: 0,
    suggestions: [],
    error: false,
    documentKindFilters: [],
    activeContentTypeFilter: [],
    productFilters: [],
    documentVersionFilters: [],
    activeProductFilters: [],
    activeVersionFilters: [],
    activeExcludedVersionFilters: [],
    sort: "",
    intentInfo: [],
    oneBoxInfo: [],
    relatedSearches: [],
    topDocuments: [],
    popularUser: false
  };

  return initialState;
}