import React, { useState } from "react";
import "./FilterSelect.css";
import {
  Title,
  TextInput,
  Button,
  Popover,
  AccordionItem,
  AccordionToggle,
  AccordionContent,
} from "@patternfly/react-core";
import FilterCheckList from "./FilterCheckList";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import filter from "lodash/filter";
import findIndex from "lodash/findIndex";
import debounce from "lodash/debounce";
import { PopoverProps } from "@patternfly/react-core/dist/js/components/Popover/Popover";

interface IFilterProps {
  id: string;
  heading: string;
  options: string[];
  selectedItemsArray: string[];
  onUpdate: (newSelectedOptions: string[], value: string) => any;
  onUpdateVersions?: (
    versionsSelected: string[],
    currProduct: string,
    allVersions: string[],
  ) => any;
  additionalInfoPopover?: PopoverProps;
  disableCollapse?: Boolean;
  toggleText: string;
  productVersions: any[];
  contentTypes: any[];
  activeProductVersions: any[];
  keyProp: string;
  isDisabled: boolean;
  isMobile: boolean;
}

function moveItemsOnTop(allItems: string[], itemsToMoveOnTop: string[]) {
  if (itemsToMoveOnTop.length === 0) return allItems;

  // validItemsToMoveOnTop is used to keep valid filters, which shoud also be present in checklist, from query params user can select * etc as filter, so we don't want to put * into the checklist.
  const validItemsToMoveOnTop: string[] = [];
  const removedItems = filter(allItems, (x) => {
    const isXValidItemToMoveOnTop =
      findIndex(itemsToMoveOnTop, (y) => `${y}` == `"${x.heading}"`) > -1;
    isXValidItemToMoveOnTop && validItemsToMoveOnTop.push(x);
    return !isXValidItemToMoveOnTop;
  });
  //SYDNEY NOTE - this needs to be spread... both objects
  return [...validItemsToMoveOnTop, ...removedItems];
}

export default function Filter(props: IFilterProps) {
  const [searchStr, setSearchStr] = useState("");
  const [inputStr, setInputStr] = useState("");
  const debouncedSetSearchStr = debounce((v) => setSearchStr(v), 400);
  const hideSearchBox =
    props.disableCollapse ||
    (get(props.options, "length") <= 5 && isEmpty(searchStr.trim()));

  let copyOfPropOptions = props?.options;

  let copyOfPropSelectedItemsArr = props?.selectedItemsArray;
  copyOfPropSelectedItemsArr?.forEach((p) => {
    p = p.replace(/^"(.*)"$/, "$1");

    let tempObj = {
      heading: p,
      solrKey: p,
      numResuts: 0,
    };
    //@ts-ignore
    copyOfPropOptions?.push(tempObj);
  });
  const uniqueArray = copyOfPropOptions.filter(
    (object, index) =>
      index ===
      copyOfPropOptions.findIndex(
        (obj) => obj["heading"] === object["heading"],
      ),
  );

  const itemsInOrder = moveItemsOnTop(uniqueArray, props.selectedItemsArray);

  const [isSectionExpanded, setIsSectionExpanded] = React.useState(
    `bordered-toggle${props.toggleText}`,
  );

  const onExpandToggle = (id: string) => {
    if (id === isSectionExpanded) {
      setIsSectionExpanded("");
    } else {
      setIsSectionExpanded(id);
    }
  };

  function clearSearchStr() {
    setInputStr("");
    setSearchStr("");
  }

  function clearSelectedItems() {
    console.log("in clear selected items");
    props.onUpdate([], "");
  }

  function getTitleTemplate() {
    return (
      <div className="cpsearch-filter-select--heading-group">
        <Title
          headingLevel="h2"
          size="md"
          id={props.heading}
          className="bold cpsearch-filter-select--heading">
          {props.heading}
        </Title>
        {props.additionalInfoPopover && (
          <Popover
            data-testid="popover"
            aria-label={props.additionalInfoPopover["aria-label"]}
            bodyContent={props.additionalInfoPopover.bodyContent}
            footerContent={props.additionalInfoPopover.footerContent}>
            <span className="cpsearch-filter-select--popover-trigger">
              <pfe-icon
                class="info"
                icon="fas-question-circle"
                size="sm"
                color="lightest"></pfe-icon>
            </span>
          </Popover>
        )}
        {props.selectedItemsArray.length > 0 && (
          <div className="cpsearch-filter-select--count">
            {/* if we add in functionality to select more than one product, can bring back this badge with selection count */}
            {/* <Badge
              data-testid="selected-count-badge"
              aria-label={`${props.selectedItemsArray.length} filter(s) selected`}
            >
              {props.selectedItemsArray.length}
            </Badge> */}
            <Button
              className="cpsearch-filter-select--clear-button"
              variant="link"
              onClick={clearSelectedItems}>
              Clear
            </Button>
          </div>
        )}
      </div>
    );
  }

  function getBodyTemplate() {
    return (
      <>
        {!hideSearchBox && (
          <div className="cpsearch-searchbox">
            <label data-testid="search-box-label" className="sr-only">
              Filter for {props.heading}
            </label>
            <TextInput
              data-testid="text-input"
              className="cpsearch-filter-select--input"
              aria-label={`filter for ${props.heading}`}
              value={inputStr}
              placeholder="Search"
              iconVariant="search"
              type="text"
              onChange={(v) => {
                setInputStr(v);
                debouncedSetSearchStr(v);
              }}
              isDisabled={props.isDisabled}
            />
          </div>
        )}
        <FilterCheckList
          key={props.keyProp}
          options={itemsInOrder}
          searchString={searchStr}
          selectedOptions={props.selectedItemsArray}
          onUpdate={props.onUpdate}
          onUpdateVersions={props.onUpdateVersions}
          clearSearchStr={clearSearchStr}
          productVersions={props.productVersions}
          activeProductVersions={props.activeProductVersions}
          contentTypes={props.contentTypes}
          isDisabled={props.isDisabled}
          isMobile={props.isMobile}
        />
      </>
    );
  }

  if (isEmpty(props.options)) {
    return null;
  }

  return (
    <>
      <AccordionItem>
        <AccordionToggle
          id={props.id}
          onClick={() => {
            onExpandToggle(`bordered-toggle${props.toggleText}`);
          }}
          isExpanded={
            isSectionExpanded === `bordered-toggle${props.toggleText}`
          }
          >
          {props.toggleText}
        </AccordionToggle>
        <AccordionContent
          
          isHidden={isSectionExpanded !== `bordered-toggle${props.toggleText}`}>
          {getBodyTemplate()}
        </AccordionContent>
      </AccordionItem>
    </>
  );
}
