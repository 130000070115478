import ErrorState from "../ErrorState/ErrorState";
import { renderSkeleton } from "../utils/Utils";

export default function ErrorComponent({topPagesData}) {
  return (
    <div className="error-state__wrapper">
      <ErrorState titlePrimary={"Something went wrong."} titleSecondary={"Please try again."} buttonText={"Back to home"} buttonLink={"/search"} />
      <p className="troubleshoot-text">Or <a href="https://access.redhat.com/support/cases/#/troubleshoot">troubleshoot your issue</a></p>
      <div className="card-container">
        <single-card-bordered-top data={JSON.stringify(topPagesData)}></single-card-bordered-top>
      </div>
    </div>
  );
}