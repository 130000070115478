import PFElement from "@patternfly/pfelement"

class DoubleCardBorderedTop extends PFElement {
  static get tag() {
    return "double-card-bordered-top"
  }

  static get events() {
    return { ClickEvent: `${this.tag}:click` }
  }

  set data(data) {
    this.cardTitleOne.textContent = data?.trendingSearchesData[0]?.title
    this.cardTitleTwo.textContent = data?.topPagesData[0]?.title
    this.linkOneSectionOne.textContent = data?.trendingSearchesData[0]?.[0]?.text
    this.linkTwoSectionOne.textContent = data?.trendingSearchesData[0]?.[1]?.text
    this.linkThreeSectionOne.textContent = data?.trendingSearchesData[0]?.[2]?.text
    this.linkOneSectionOne.href = data?.trendingSearchesData[0]?.[0]?.link
    this.linkTwoSectionOne.href = data?.trendingSearchesData[0]?.[1]?.link
    this.linkThreeSectionOne.href = data?.trendingSearchesData[0]?.[2]?.link
    this.linkOneSectionTwo.textContent = data?.topPagesData[0]?.[0]?.text
    this.linkTwoSectionTwo.textContent = data?.topPagesData[0]?.[1]?.text
    this.linkThreeSectionTwo.textContent = data?.topPagesData[0]?.[2]?.text
    this.linkOneSectionTwo.href = data?.topPagesData[0]?.[0]?.link
    this.linkTwoSectionTwo.href = data?.topPagesData[0]?.[1]?.link
    this.linkThreeSectionTwo.href = data?.topPagesData[0]?.[2]?.link
  }

  

  get html() {
    return `
    <style>
      :host{ display:block }
      .result-card{
        border-radius: 3px 3px 0px 0px;
        border: 1px solid #D2D2D2;
        margin-bottom: 32px;
        margin-right: 0;
      }

      .top-border{
        position: relative;
        background-color: #EE0000;
        height: 4px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        margin-left: -1px;
        margin: 0 -1px;
      }

      .card-body{
        padding-bottom: 32px;
        display: flex;
        justify-content: space-around;
      }

      .title-one, .title-two{
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        color: #151515;
        margin: 24px 0 8px 0;
      }

      .section-two{
        display: flex;
        width: 50%;
      }

      .link-container{
        display: flex;
        flex-direction: column;
      }

      .single-link{
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #0066CC;
        text-decoration: none;
        margin-top: 16px;
      }

      .vertical-divider{
        margin-top: 64px;
        height: 100px;
        width: 1px;
        background-color: #D2D2D2;
      }

      .horizontal-divider{
        display: none;
      }

      .section-one{
        margin-left: 24px;
        width: 50%;
      }

      .section-two-body{
        flex-direction: column;
        margin: 0 24px;
      }
      
      @media (max-width: 768px) {
 
      }

      @media (max-width: 480px) {
        .result-card{
          margin: 0 16px;
        }
        .card-body{
          padding: 0 16px 24px;
          flex-direction: column;

        }

        .section-one, .section-two{
          width: 80%;
        }

        .title-one, .title-two{
          font-size: 18px;
          margin: 16px 0 8px;
        }

        .single-link{
          font-size: 16px;
        }
        .vertical-divider{
          display: none;
        }

        .horizontal-divider{
          display: block;
          margin: 24px 0 8px;
          height: 1px;
          background-color: #D2D2D2;
        }
      }
    </style>
      <div class="result-card">
        <div class="top-border"></div>
        <div class="card-body">
        <div class="section-one">       
          <p class="title-one"></p>
          <div class="link-container">
            <a class="single-link link-one-section-one"></a>
            <a class="single-link link-two-section-one"></a>
            <a class="single-link link-three-section-one"></a>
          </div>
        </div>
        <div class="horizontal-divider"></div>
        <div class="section-two">
          <div class="vertical-divider"></div>
          <div class=section-two-body>
           <p class="title-two"></p>
            <div class="link-container">
                <a class="single-link link-one-section-two"></a>
                <a class="single-link link-two-section-two"></a>
                <a class="single-link link-three-section-two"></a>
            </div>
          </div>
        </div>
      </div>
    `
  }

  static get observedAttributes() {
    return ["data"];
  }

  constructor() {
    super(DoubleCardBorderedTop)
    this.cardTitleOne = this.shadowRoot.querySelector('.title-one')
    this.cardTitleTwo = this.shadowRoot.querySelector('.title-two')
    this.linkOneSectionOne = this.shadowRoot.querySelector('.link-one-section-one')
    this.linkTwoSectionOne = this.shadowRoot.querySelector('.link-two-section-one')
    this.linkThreeSectionOne = this.shadowRoot.querySelector('.link-three-section-one')
    this.linkOneSectionTwo = this.shadowRoot.querySelector('.link-one-section-two')
    this.linkTwoSectionTwo = this.shadowRoot.querySelector('.link-two-section-two')
    this.linkThreeSectionTwo = this.shadowRoot.querySelector('.link-three-section-two')
  }

  attributeChangedCallback(attr, oldVal, newVal) {
    super.attributeChangedCallback(attr, oldVal, newVal);

    switch(attr) {
      case "data":

        this.data = JSON.parse(newVal);
        break;
    }
  }
}

PFElement.create(DoubleCardBorderedTop);

export default DoubleCardBorderedTop;