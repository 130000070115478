import PFElement from "@patternfly/pfelement"
import "@patternfly/pfe-autocomplete";
import "@cpelements/cp-search-autocomplete";


class InputForm extends PFElement {

  static get tag() {
    return "input-form"
  }

  static get properties() {
    return {
      q: {
        type: String,
        observer: "_qChanged"
      },
      disabled: {
        type: String,
        observer: "_disabledChanged"
      }
    }
  }

  static get events() {
    return { SubmitEvent: `${this.tag}:submit` }

  }

  get html() {
    return `
      <style>
        :host{ display:block }

        button.search-button {
          margin-bottom: 2px !important;
          background-color: none !important;
        }

        cp-search-autocomplete {
          flex: 1;
          align-items: center;

        }

        @media(max-width: 480px) {
          pfe-autocomplete{
            margin-left: 16px;
            margin-top: 16px;
          }
          }

      
        
      </style>
      <form data-testid="search-form" role="search">
      <cp-search-autocomplete 
      data-testid="cp-search-autocomplete" aria-label="Search the Customer Portal" placeholder="Search the Customer Portal" path="https://access.redhat.com/webassets/avalon/j/data.json" required pattern=".*[a-zA-Z0-9]+.*"></cp-search-autocomplete>
      </form>
    `;
  }

  constructor() {
    super(InputForm)

    this.submitHandler = this.submitHandler.bind(this);

    this.form = this.shadowRoot.querySelector('form');
    this.form.addEventListener('submit', this.submitHandler);

    this.staticAutocomplete = this.shadowRoot.querySelector('cp-search-autocomplete')
    this.staticAutocomplete.addEventListener('pfe-autocomplete:search-event', this.submitHandler);

    this.input = this.shadowRoot.querySelector('input');
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.form.removeEventListener('submit', this.submitHandler);
    // this.input.removeEventListener("cp-search-autocomplete", this._inputHandler);
  }

  submitHandler(e) {
    e.preventDefault();

  //   document.addEventListener("pfe-autocomplete:search-event", function (event) {
  //     console.log(event.detail.searchValue)
  //     // $scope.query = ;
  //     // $scope.search(null, true);
  //     this.emitEvent(InputForm.events.SubmitEvent, { detail: { inputValue: event.detail.searchValue } })
  // });
    if (!e.detail.searchValue) {
      return;
    }
    let inputVal = e.detail.searchValue.trim();
    if(inputVal === ""){
      return;
    }
    this.emitEvent(InputForm.events.SubmitEvent, { detail: { inputValue: e.detail.searchValue } })
  }

  _qChanged(oldVal, newVal) {

    // this.input.value = newVal

    // this.staticAutocomplete.input.value = newVal
    // this.staticAutocomplete.query = newVal


  }

  _disabledChanged() {
    if (this.disabled === "true") {
      this.staticAutocomplete.setAttribute("disabled", "");
    } else {

      this.staticAutocomplete.removeAttribute("disabled");
    }
  }

  static get observedAttributes() {
    return ["data"];
  }

  attributeChangedCallback(attr, oldVal, newVal) {
    super.attributeChangedCallback(attr, oldVal, newVal);

    switch(attr) {
      case "data":

        this.data = JSON.parse(newVal);
        break;
    }
  }



}

PFElement.create(InputForm)
export default InputForm