import PFElement from "@patternfly/pfelement"

class SuggestionState extends PFElement {
  static get tag() {
    return "suggestion-state"
  }

  static get events() {
    return { ClickEvent: `${this.tag}:click` }
  }

  set data(data) {
    const sanitize = (string) => {
    const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        "/": '&#x2F;',
    };
    const reg = /[&<>"'/]/ig;
    return string.replace(reg, (match)=>(map[match]));
  }

    this.searchTermValue.textContent = data.searchTerm
    let suggestions = data.suggestions
    if (suggestions && suggestions.length > 0) {
      this.suggestionsSubheading.textContent = 'Did you mean:'
      let result = '';
      suggestions.forEach((s, i) => {
        result += `<p key=${i} class="no-results__suggestion"><a href="?q=${sanitize(s)}">${sanitize(s)}</a></p>`;
      })
      this.suggestionDiv.innerHTML = result;
    }
  }

  get html() {
    return `
    <style>
      :host{ display:block }
      .no-results__wrapper{
        margin-top: 64px;
      }
      
      .no-results__heading{
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #000000;
        text-align: center;
        margin: 0;
      }

      .no-results__suggestion-wrapper{
        text-align: center;
      }
      
      #no-results__search-term{
        color: #000000;
        font-weight: 700;
      }
      
      .no-results__suggestion{
        font-size: 18px;
        line-height: 27px;
        font-weight: 600;
        margin: 0;
      }

      a{
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        text-decoration-line: underline;
        color: #0066CC;
      }
      
      .no-results__subheading{
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #000000;
        margin: 16px 0 0;
      }

      .no-results__suggestion{
        margin-top: 6px;
      }
      
      .no-results__tips{
        color: #151515;
        font-size: 18px;
        line-height: 20px;
        margin-top: 8px;

      }
      @media (max-width: 480px) {
        .no-results__wrapper{
          margin-top: 48px;
        }
        .no-results__heading{
          font-size: 16px;
        }
        .no-results__suggestion, .no-results__tips{
          font-size: 16px;
        }
        .no-results__subheading{
          font-size: 16px;
          margin-top: 12px;
        }
        .no-results__suggestion{
          margin-top: 12px;
        }
      }
    </style>

    <div class="no-results__wrapper">
      <p class="no-results__heading">No results found for: <span id="no-results__search-term"></span></p>
      <div class="no-results__suggestion-wrapper">
        <p class="no-results__subheading" id="no-results__subheading_one"></p>
        <div id="suggestion-items"></div>
      </div>
    </div>
    `
  }

  static get observedAttributes() {
    return ["data"];
  }

  constructor() {
    super(SuggestionState);

    this._clickHandler = this._clickHandler.bind(this);

    this.searchTermValue = this.shadowRoot.querySelector('#no-results__search-term')
    this.suggestionsSubheading = this.shadowRoot.querySelector('#no-results__subheading_one')
    this.suggestionDiv = this.shadowRoot.querySelector('#suggestion-items');
    this.shadowRoot.addEventListener("click", this._clickHandler);
  }

  attributeChangedCallback(attr, oldVal, newVal) {
    super.attributeChangedCallback(attr, oldVal, newVal);

    switch (attr) {
      case "data":
        this.data = JSON.parse(newVal);
        break;
    }
  }

  _clickHandler(event) {
    const target = event.target;

    if (target.tagName.toLowerCase() !== 'a') {
      return;
    }

    const url = new URL(event.target.href);
    const params = new URLSearchParams(url.search);
    const q = params.get("q") || '';
    this.emitEvent(SuggestionState.events.ClickEvent, {
      detail: {
        q
      }
    });
  }
}

PFElement.create(SuggestionState);

export default SuggestionState;