import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Switch, Route } from "react-router-dom";

// //@ts-ignore
import(
  /*webpackIgnore: true*/ `${window.location.protocol}//${window.location.host}/services/primer/js/primer.js`
).then((primer) => {
  primer.components.include("pfe-band");
  primer.components.include("pfe-cta");
  primer.components.include("pfe-autocomplete");
  primer.components.include("pfe-datetime");

  const preselectedDocumentkindFilters = {
    "/knowledgebase": ['"Solution"', '"Article"'],
  };

  ReactDOM.render(
    <BrowserRouter basename="/search">
      <React.StrictMode>
        <Switch>
          <Route
            exact
            path="/"
            render={(routeProps) => <App routeProps={routeProps} />}
          />
          <Route exact path="/knowledgebase">
            <App {...preselectedDocumentkindFilters} />
          </Route>
        </Switch>
      </React.StrictMode>
    </BrowserRouter>,
    document.getElementById("root"),
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
