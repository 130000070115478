export default function EmptyStateSuggestions({ topPagesData, trendingSearchesData }) {
  return (
    <>
      <p className="troubleshoot-text">Or <a href="https://access.redhat.com/support/cases/#/troubleshoot">troubleshoot your issue</a></p>
      <div className="empty-state-container">
        {/* rendering single card until trending searches endpoint is completed */}
        {/* <double-card-bordered-top data={JSON.stringify({ trendingSearchesData, topPagesData })}></double-card-bordered-top> */}
        <single-card-bordered-top data={JSON.stringify(topPagesData)}></single-card-bordered-top>
      </div>
    </>
  );
}